

body {
    padding: 0;
    margin: 0;

    --pm: 1rem;
}


main {
    
    padding: var(--pm);
}
.page--header {
    nav{
        background: rgba(0,0,0,.2);
        padding: 1rem;

    ul {
        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: nowrap;
        gap: 1rem;

    }
}
}

zing-grid#grid{

    .mapImage{
        height: auto;
    }
    [data-field-index*="carImage"] img {
        width: 100%;
        max-width: 100%;
    }
    img {
        width: 100%;
        max-width: 100%;
    }

}