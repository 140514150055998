
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial;
}

#thing{
  display: block; z-index: 999; margin: 5px; position: absolute; top: 0px; right: 0px; width: 50px; height: 50px; background: goldenrod; border-radius: 50%;
}
      
.navbar__logo{
  width: 200px;

  enable-background:new 0 0 841.89 595.28;

  .st0 {
    fill: none;
    stroke: #000;
    stroke-width: 2.2367;
    stroke-miterlimit: 10
  }
}
/**
 * 
 * Start styles
 *
**/

.zg-body {
        background: #fff;
      }

      zing-grid[loading] {
        height: 500px;
      }

      /** Expandable Row Trigger **/

      .zg-body .arrow {
        display: inline-block;
        transform: rotate(90deg);
        transform-origin: 0% 0%;
        cursor: pointer;
        font-size: 1.5rem;
        position: relative;
        left: 2.5rem;
        top: 0.25rem;
      }

      .zg-body .active .arrow {
        transform: rotate(180deg);
        left: 2.5rem;
        top: 1.5rem;
      }

      /** Expandable Row Header **/

      .zg-body .header {
        display: flex;
        align-items: center;
      }

      .zg-body .team--info {
        margin-left: 2.5rem;
      }

      /** Expandable Row CSS **/

      .zg-body .default {
        border-bottom: 1px solid #ebebeb;
      }

      .zg-body zg-row:hover {
        cursor: pointer;
        background-color: #f5f7fa;
        transition: background-color 0.25s ease-in;
      }

      .zg-body .expandable {
        background: #f5f5f5;
        padding: 1rem 0;
        display: none;
      }

      .zg-body .active .expandable {
        display: block;
      }

      /** Set padding **/

      /** NOTE: Css looks off here, but it is to fix how it looks on site **/

      .zg-body .default,
      .expandable {
        line-height: 1.5rem;
        padding: 0.9rem 1rem;
      }

      .zg-body .default--arrow {
        padding: 0.9rem 0;
      }

      .zg-body zg-cell {
        padding: 0;
      }

      /** hide layout **/

      .zg-body zg-layout {
        display: none;
      }

      zing-grid {
        position: relative;
      }

      zing-grid[loading] {
        height: 720px;
      }

      zg-load-mask {
        /* Custom Loading Animated Gif */
        /*
      background-image: url("https://storage.googleapis.com/zg-demos.appspot.com/starwars_loading.gif");
      background-position: center;
      background-repeat: repeat-x;

      background-size: contain;
      height: calc(100% - 65px);
      opacity: 0.95;
      top: 65px;
*/
        background-repeat: no-repeat;
        background-size: contain;
        /* height: calc(100% - 65px); */
        /* opacity: 0.25; */
        top: 65px;
        /* background-image: url(https://i.pinimg.com/originals/9e/00/ca/9e00ca1….gif); */
        /* background-position: center; */
        width: auto;
        background-color: #27173e;
        position: absolute;
        display: inline-block;
        height: initial !important;
        top: 50%;
        left: 50%;
        padding: 1vh;
      }
      zg-load-mask[hidden]{
        display: none !important;
      }

      zg-caption {
        background: transparent;
        color: black;
      }

      img.cross {
        background: red;
        width: 10px;
      }

      img.checkmark {
        background: green;
        width: 10px;
      }

      zg-cell {
        padding: 5px !important;
      }

      img[src*="arbetsformedl"] {
        width: initial;
      }

      svg#Lager_1 {
        height: 50px;
      }

      zg-header {
        //background: transparent url(https://w.wallhaven.cc/full/lm/wallhaven-lmxmxy.png) center center !important;

      }

      zg-caption {
        //background: rgba(255, 255, 255, 0.2);
        //color: black;
      }


      zg-search:before {
    font-size: 1.2rem;
    padding: 5px;
    line-height: 2.5rem;
    color: black;
    height: 100%;
}
      body {
    padding: 1.5rem !important;
}

* {
    box-sizing: content-box;
}

section {}

zing-grid > section {
    padding: 1rem;
}

zg-footer > div {
    padding: 1rem;
}

section.zg-body {
    width: 80% !important;
    margin: 0 auto;
}
.caption-pager--container {
    padding: 1rem;
}

form-group#filters {
    display: flex;
}

form-group#filters>* {
    margin-right: 15px;
    border-bottom: 2px solid black;
}
zg-menu-item *{
    font-size: 2rem;
    color: black !important;
}

zg-menu#columnMenu {
    overflow-y: auto;
    height: 100vh;
}

zg-caption zg-button zg-icon {
    filter: invert(1);
}

/**
 * 
 * End styles
 *
**/

zing-grid[loading] {
  height: 400px;
}

.accordion-flex-layout {
  --zg-cell-vertical-align: top;
}

/* target accordion for mobile and display as column*/
zing-grid[viewport="mobile"] .accordion-container {
  flex-direction: column;
  flex-grow: 1;
}

/* Main accordion styling */
.accordion input {
  display: none;
}

.accordion label {
  position: relative;
  display: block;
  background: #eee;
  border-radius: .25em;
  cursor: pointer;
  margin-bottom: .125em;
  padding: .75rem 1em;
  z-index: 20;
}

.accordion label:hover {
  background: #ccc;
}

.accordion input:checked+label {
  background: #ccc;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  margin-bottom: 0;
}

.accordion label::after {
  content: '+';
  position: absolute;
  right: 5px;
}

.accordion input:checked+label::after {
  content: '-';
  position: absolute;
  right: 5px;
}

.accordion article {
  background: #f7f7f7;
  /*
* max-height allows height transition in CSS
* use height: 0px; otherwise
*/
  max-height: 0px;
  overflow: hidden;
  z-index: 10;
  opacity: 0;
  /* allow closing transition */
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.accordion article {
  padding: 1em;
  cursor: default;
}

.accordion input:checked article {}

.accordion input:checked~article {
  border-bottom-left-radius: .25em;
  border-bottom-right-radius: .25em;
  /*
* max-height allows height transition in CSS
* use height: auto; otherwise
*/
  max-height: 500px;
  margin-bottom: .125em;
  opacity: 1;
  /* allow opening transition */
  -webkit-transition: all 1s ease;
  /* Safari */
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}